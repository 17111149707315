<template>
  <div>
    <b-tabs content-class="p-3 bg-white">
      <b-tab title="Set Up" active>
        <InputText
          textFloat="Name"
          placeholder="Search"
          type="text"
          isRequired
          v-model="random_reward.name"
          name="name"
          class="w-4"
        />
        <hr />
        <div>
          <div class="title-tabs">Reward Detail</div>
          <div class="pt-3 pb-3">
            <div class="text-black">
              <div>
                <div class="content-between">
                  <div>
                    <b-button
                      class="btn-select-branch w-100 w-sm-unset text-nowrap"
                      @click.prevent="openProductModal"
                    >
                      Select Item
                      <font-awesome-icon
                        icon="chevron-right"
                        class="ft-14 ml-2"
                      />
                    </b-button>
                    <span class="ml-2"> Items Selected </span>
                  </div>
                  <div>
                    <b-button
                      class="btn-select-branch w-100 w-sm-unset mr-2 text-nowrap"
                      @click.prevent="addReward(4)"
                    >
                      <font-awesome-icon
                        icon="plus-square"
                        class="ft-14 mr-2"
                      />
                      Add Point Reward
                    </b-button>
                    <b-button
                      class="btn-select-branch w-100 w-sm-unset text-nowrap"
                      @click.prevent="addReward(1)"
                    >
                      <font-awesome-icon
                        icon="plus-square"
                        class="ft-14 mr-2"
                      />
                      Add No Reward
                    </b-button>
                  </div>
                </div>

                <b-table
                  :fields="fields"
                  :items="reward_items"
                  :busy="isBusy"
                  show-empty
                  class="mt-3"
                  empty-text="No matching records found"
                  responsive
                >
                  <template #cell(id)="row">
                    <b-button
                      size="sm"
                      @click="row.toggleDetails"
                      class="mr-2"
                      variant="custom-link"
                    >
                      <font-awesome-icon
                        icon="chevron-down"
                        v-if="!row.detailsShowing"
                      />

                      <font-awesome-icon icon="chevron-up" v-else />
                    </b-button>
                  </template>
                  <template v-slot:cell(imageUrl)="data">
                    <div class="position-relative picture-text pic-table">
                      <div
                        v-if="data.item.imageUrl"
                        class="square-box b-contain image"
                        v-bind:style="{
                          'background-image': 'url(' + data.item.imageUrl + ')',
                        }"
                      ></div>
                      <div
                        v-else-if="data.item.image_url"
                        class="square-box b-contain image"
                        v-bind:style="{
                          'background-image':
                            'url(' + data.item.image_url + ')',
                        }"
                      ></div>
                      <div v-else>
                        <div
                          class="square-box b-contain image"
                          v-bind:style="{
                            'background-image': 'url(' + default_image + ')',
                          }"
                        ></div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell(quantity)="data">
                    <InputText
                      textFloat=""
                      v-model="data.item.quantity"
                      placeholder="0"
                      type="number"
                      class="mb-0"
                    />
                  </template>
                  <template v-slot:cell(win_rate)="data">
                    <InputText
                      textFloat=""
                      v-model="data.item.win_rate"
                      placeholder="0"
                      type="number"
                      class="mb-0"
                      :useSub="true"
                    />
                  </template>
                  <template v-slot:cell(active)="data">
                    <!-- <div v-show="false">{{ data.item.active }}</div> -->
                    <b-form-checkbox
                      switch
                      v-model="data.item.active"
                      :name="'' + data.item.id"
                      class="radio-active"
                      size="sm"
                      :value="1"
                      :unchecked-value="0"
                    >
                    </b-form-checkbox>
                  </template>
                  <template v-slot:cell(name)="data">
                    <div class="text-left">
                      <p class="m-0" v-if="data.item.name">
                        <b>
                          {{ data.item.name }}
                        </b>
                      </p>
                    </div>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button
                      variant="icon"
                      class="px-0"
                      :disabled="data.item.is_redeem == 1"
                    >
                      <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                  </template>
                  <template v-slot:table-busy>
                    <div class="text-center text-black my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong class="ml-2">Loading...</strong>
                    </div>
                  </template>
                  <template #row-details="row">
                    <b-card>
                      <b-row
                        class="align-items-center justify-content-space-aroud"
                      >
                        <b-col cols="12" sm="3" class="text-left">
                          <b-form-group
                            label="Condition For Receiving The Goods"
                            v-slot="{ ariaDescribedby }"
                          >
                            <b-form-radio-group
                              :id="'receiving-' + row.item.id"
                              v-model="row.item.pick_up_type"
                              :aria-describedby="ariaDescribedby"
                              :name="'receiving-' + row.item.id + '-slot'"
                            >
                              <b-form-radio :value="0" checked
                                >None
                              </b-form-radio>
                              <b-form-radio :value="1"
                                >Pick up at branch
                              </b-form-radio>
                              <b-form-radio :value="2"
                                >Pick up at home
                              </b-form-radio>
                            </b-form-radio-group>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="3" class="text-left">
                          <b-form-group
                            label="Availiability"
                            v-slot="{ ariaDescribedby }"
                          >
                            <b-form-radio-group
                              v-model="row.item.availability"
                              :aria-describedby="ariaDescribedby"
                              :id="'availiability-' + row.item.id"
                              :name="'availiability-' + row.item.id + '-slot'"
                            >
                              <b-form-radio :value="0" checked>
                                None
                              </b-form-radio>
                              <b-form-radio :value="1">Ready </b-form-radio>
                              <b-form-radio :value="2">Prepare </b-form-radio>
                            </b-form-radio-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>
                  </template>
                </b-table>

                <!-- 
                 
                    </b-table>
                  </b-col>
                </b-row> -->
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    class="form-inline justify-content-start"
                  >
                    <p class="mb-0 p-gray text-nowrap">
                      Showing
                      <span id="from-row">{{
                        rows == 0
                          ? 0
                          : ((filter.PageNumber - 1) * filter.RecordPerPage + 1)
                            | numeral("0,0")
                      }}</span>
                      to
                      <span id="to-row">
                        {{
                          filter.RecordPerPage * filter.PageNumber > rows
                            ? rows
                            : (filter.RecordPerPage * filter.PageNumber)
                              | numeral("0,0")
                        }}</span
                      >
                      of
                      <span id="total-row">{{ rows | numeral("0,0") }}</span>
                      entries
                    </p>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    class="form-inline justify-content-end"
                  >
                    <div>
                      <b-pagination
                        v-model="filter.PageNumber"
                        :total-rows="rows"
                        :per-page="filter.RecordPerPage"
                        @change="pagination"
                        first-text="First"
                        prev-text="Prev"
                        next-text="Next"
                        last-text="Last"
                        class="m-md-0"
                      ></b-pagination>
                    </div>
                    <div class="ml-2">
                      <b-form-select
                        v-model="filter.RecordPerPage"
                        @change="handlePerpage"
                        :options="pageOptions"
                      ></b-form-select>
                    </div>
                  </b-col>
                  <!-- <b-col sm="12" v-if="v.items.selectProduct.$error">
                    <p
                      class="text-danger"
                      v-if="form.items.selectProduct.RecordPerPage == 0"
                    >
                      Please select at least 1 product.
                    </p>
                    <p class="text-danger" v-else>
                      Please enter points more than 0 on products.
                    </p>
                  </b-col> -->
                </b-row>
                <!-- <div v-if="pleaseSelectProduct"></div> -->
              </div>
              <hr />
            </div>
          </div>
        </div>
        <div class="no-gutters bg-white mt-3 py-2 px-3">
          <b-form-checkbox
            switch
            v-model="random_reward.active"
            class="radio-active"
            size="lg"
            :value="1"
            :unchecked-value="0"
          >
            <span class="ml-2 main-label">{{
              random_reward.active ? "Active" : "Inactive"
            }}</span>
          </b-form-checkbox>
        </div>
      </b-tab>
      <b-tab title="Report"> <Report /></b-tab
    ></b-tabs>
    <ProductModal
      ref="ProductListModal"
      :form="reward_items"
      :freezeObj="freezeObj"
      @submitProduct="submitProduct"
    />
    <FooterAction routePath="/manageStaff" @submit="submit" />
  </div>
</template>

<script>
import ProductModal from "./components/Modal";
import Report from "./components/Report";
export default {
  components: { ProductModal, Report },
  data() {
    return {
      random_reward: {
        id: 0,
        name: "",
        active: 1,
      },
      reward_items: [],
      delete_items: [],

      data: [],
      fields: [
        { key: "id", label: "" },
        { key: "imageUrl", label: "Image", thClass: "table-width" },
        { key: "name", label: "Name / Description" },
        { key: "quantity", label: "Quantity" },
        { key: "win_rate", label: "Win Rate" },
        { key: "active", label: "Active" },
        { key: "action", label: "" },
      ],
      isBusy: false,
      rows: 0,
      filter: {
        random_reward_id: this.$route.params.id,
        PageNumber: 1,
        RecordPerPage: 10,
      },
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      freezeObj: [],
    };
  },
  created() {
    if (this.$route.params.id != 0) {
      this.getData();
      // this.getItems();
    }
  },
  methods: {
    async getData() {
      const resp = await this.axios(`/RandomReward/${this.$route.params.id}`);

      this.random_reward = resp.data.detail.random_reward;
      this.rows = resp.data.detail.random_reward_item.length;
      this.reward_items = resp.data.detail.random_reward_item.map((el) => {
        return {
          ...el,
          name: el.item_name,
          imageUrl: el.item_img,
          _showDetails: true,
        };
      });
    },
    addReward(type) {
      this.reward_items.push({
        id: 0,
        random_reward_id: 0,
        random_reward_item_type: type,
        reference_id: 0,
        point: 0,
        quantity: 0,
        win_rate: 0,
        pick_up_type: 0,
        availability: 0,
        active: 1,
        item_img: "",
        item_name: type == 1 ? "No Reward" : "Point Reward",
        name: type == 1 ? "No Reward" : "Point Reward",
        imageUrl: "",
        _showDetails: true,
      });
    },

    handlePerpage() {},
    pagination() {},
    async getKeyByValue(value) {},
    submitProduct(product) {
      let temp = [
        ...product[0].map((el) => {
          return {
            ...el,
            id: el.id,
            random_reward_item_type: 1,
            reference_id: 0,
          };
        }),
        ...product[1].map((el) => {
          return {
            ...el,
            id: el.id,
            random_reward_item_type: 2,
            reference_id: 0,
          };
        }),
        ...product[3].map((el) => {
          return {
            ...el,
            id: el.id,
            random_reward_item_type: 3,
            reference_id: 0,
          };
        }),
      ];

      this.reward_items = temp;
    },
    async openProductModal(type) {
      this.$refs.ProductListModal.show(1, this.reward_items);
    },
    async submit() {
      this.$EventBus.$emit("showLoading");
      const res = await this.axios.post("/RandomReward", {
        reward_items: this.reward_items,
        random_reward: this.random_reward,
        delete_items: [],
      });

      this.$EventBus.$emit("hideLoading");
      if (res.data.result == 1) {
        this.successAlert();
        this.$router.push("/random-reward");
      } else {
        this.errorAlert(res.data.message);
      }
    },
  },
};
</script>

<style>
.justify-content-space-aroud {
  justify-content: space-around;
}
</style>
