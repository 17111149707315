<template>
  <div>
    <StatPanel
      :column="statColumn"
      :data="data.analysis"
      :isLoading="isLoading"
    />

    <!-- <div class="content-between my-3">
      <div>
        <b-button
          class="btn-select-branch w-100 w-sm-unset text-nowrap"
          @click.prevent="openProductModal"
        >
          Select Item
          <font-awesome-icon icon="chevron-right" class="ft-14 ml-2" />
        </b-button>
        <span class="ml-2"> Items Selected </span>
      </div>
      <div>
        <b-button
          class="btn-select-branch w-100 w-sm-unset mr-2 text-nowrap"
          @click.prevent="addReward(1)"
        >
          <font-awesome-icon icon="plus-square" class="ft-14 mr-2" />
          Add Point Reward
        </b-button>
        <b-button
          class="btn-select-branch w-100 w-sm-unset text-nowrap"
          @click.prevent="addReward(4)"
        >
          <font-awesome-icon icon="plus-square" class="ft-14 mr-2" />
          Add No Reward
        </b-button>
      </div>
    </div> -->

    <b-table
      :fields="fields"
      :items="data.order_detail.redeem_orders"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
      responsive
    >
      <template v-slot:cell(updated_time)="data">
        <div>{{ data.item.updated_time | moment($formatDateNew) }}</div>
        <div class="time-color">
          {{ data.item.updated_time | moment("HH:mm:ss") }}
        </div>
      </template>
      <template v-slot:cell(pick_up_type)="data">
        <div>{{ textPickup(data.item.pick_up_type) }}</div>
      </template>
      <template v-slot:cell(random_reward_order_status)="data">
        <div>{{ textStatus(data.item.random_reward_order_status) }}</div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>
    <Pagination
      @handleChangeTake="handleChangeTake"
      @pagination="pagination"
      :filter="filter"
      :rows="rows"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isBusy: false,
      statColumn: [
        {
          name: "All Redeem",
          key: "count_Redeemed",
          isPercent: false,
          isDecimal: false,
        },

        {
          name: "In Progress",
          key: "in_Progress",
          isPercent: false,
          isDecimal: false,
        },
        {
          name: "Success",
          key: "success",
          isPercent: false,
          isDecimal: false,
        },
      ],
      data: {},
      rows: 0,
      filter: {
        random_reward_id: this.$route.params.id,
        search: "",
        random_reward_order_status: [], // list int
        branch_id: [],
        PageNumber: 1,
        RecordPerPage: 5,
        page: 1,
        take: 5,
      },
      fields: [
        { key: "id", label: "" },
        { key: "customer_name", label: "Name - Surname" },
        { key: "member_id", label: "Member ID" },
        { key: "telephone", label: "Tel" },
        { key: "email", label: "Email" },
        { key: "item_name", label: "Gift" },
        { key: "pick_up_type", label: "Pick up" },
        { key: "random_reward_order_status", label: "Status" },
        { key: "updated_time", label: "Date / Time Received" },
        { key: "note", label: "Note" },
      ],
      isLoading: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    textPickup(id) {
      switch (id) {
        case 1:
          return "Pick up at branch";
        case 2:
          return "Pick up at home";

        default:
          return "None";
      }
    },
    textStatus(id) {
      switch (id) {
        case 1:
          return "Ready";
        case 2:
          return "Prepare";
        default:
          return "None";
      }
    },
    async getData() {
      const resp = await this.axios.post("/RandomReward/report", this.filter);
      console.log(resp);
      this.data = resp.data.detail;
      this.rows = resp.data.detail.order_detail.totalRecord;
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.PageNumber = 1;
      this.filter.take = value;
      this.filter.RecordPerPage = value;
      this.getData();
    },
    pagination(val) {
      this.filter.page = val;
      this.filter.PageNumber = val;
      this.getData();
    },
  },
};
</script>

<style></style>
